
export default {
    name: "FlexBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    computed: {
        classes() {
            return [
                this.blok.align,
                this.blok.justify,
                { "flex-wrap": !this.blok.noWrap },
                this.blok.customClasses,
            ]
        },
        style() {
            const style = {}
            if (this.blok.gap) {
                style.gap = `${this.blok.gap}px`
            }
            return style
        },
    },
}
